import { Reducer } from "redux";
import images from "../../shared/images";

let id: number = 1;

export interface IProject {
    id: number
    organizationName: string
    organizationText: string
    year: string
    purposeOfTheLoan: string
    loanAmount: string
    investments: string
    label: string
    logo: any
}

export interface IProjectsState {
    projects: IProject[]
}

const defaultState = {
    projects: [
        {
            id: id++,
            organizationName: 'llc «Рухом»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_1",
            loanAmount: "2",
            investments: "2,5",
            organizationText: "organization_text_1",
            label: "organization_label_1",
            logo: images.projectLogo1,
        },
        {

            id: id++,
            organizationName: 'llc «Фируз Бинокор»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_2",
            loanAmount: "4",
            investments: "9",
            organizationText: "organization_text_2",
            label: "organization_label_2",
            logo: images.projectLogo2,
        },
        {

            id: id++,
            organizationName: 'llc «Мурғи Покиза»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_3",
            loanAmount: "3",
            investments: "5",
            organizationText: "organization_text_3",
            label: "organization_label_3",
            logo: images.projectLogo3,
        },
        {

            id: id++,
            organizationName: 'llc «Армон 21»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_4",
            loanAmount: "2,5",
            investments: "3,3",
            organizationText: "organization_text_4",
            label: "organization_label_4",
            logo: images.projectLogo4,
        },
        {
            id: id++,
            organizationName: 'llc «Гардӣ 2015»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_5",
            loanAmount: "3",
            investments: "18",
            organizationText: "organization_text_5",
            label: "organization_label_5",
            logo: images.projectLogo5,
        },
        {
            id: id++,
            organizationName: 'llc «Афзалият Групп»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_6",
            loanAmount: "4",
            investments: "59",
            organizationText: "organization_text_6",
            label: "organization_label_6",
            logo: images.projectLogo6,
        },
        {
            id: id++,
            organizationName: 'llc «Иттиҳодияи истеҳсолии Нассоҷии Тоҷик»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_7",
            loanAmount: "3,0",
            investments: "5,5",
            organizationText: "organization_text_7",
            label: "organization_label_7",
            logo: images.projectLogo7,
        },
        {
            id: id++,
            organizationName: 'llc «Шабакаи Аср»',
            year: "2020",
            purposeOfTheLoan: "purpose_of_the_loan_8",
            loanAmount: "3,9",
            investments: "4,7",
            organizationText: "organization_text_8",
            label: "organization_label_8",
            logo: images.projectLogo8,
        },
        {
            id: id++,
            organizationName: 'llc «Собиров Б.Г.»',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_9",
            loanAmount: "3",
            investments: "5",
            organizationText: "organization_text_9",
            label: "organization_label_9",
            logo: null,
        },
        {
            id: id++,
            organizationName: 'llc «Ҳаммол Транc»',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_10",
            loanAmount: "1.6",
            investments: "2.6",
            organizationText: "organization_text_10",
            label: "organization_label_10",
            logo: null,
        },
        {
            id: id++,
            organizationName: 'llc «Неруи Шарқ»',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_11",
            loanAmount: "1",
            investments: "2",
            organizationText: "organization_text_11",
            label: "organization_label_11",
            logo: null,
        },
        {
            id: id++,
            organizationName: 'llc «Акваджус»',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_12",
            loanAmount: "2.4",
            investments: "3.2",
            organizationText: "organization_text_12",
            label: "organization_label_12",
            logo: null,
        },
        {
            id: id++,
            organizationName: 'llc “КАМ” “Зебо”',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_13",
            loanAmount: "3",
            investments: "4",
            organizationText: "organization_text_13",
            label: "organization_label_13",
            logo: null,
        },
        {
            id: id++,
            organizationName: 'llc «Шириниҳои Осиё»',
            year: "2021",
            purposeOfTheLoan: "purpose_of_the_loan_14",
            loanAmount: "0.6",
            investments: "1.1",
            organizationText: "organization_text_14",
            label: "organization_label_14",
            logo: null,

        }
    ]
};

const projectsReducer: Reducer<IProjectsState, any> = (state = defaultState, action): IProjectsState => {
    switch (action.type) {
        default:
            return state
    }
};

export default projectsReducer;