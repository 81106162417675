import { Reducer } from "redux";
import { ACTION_TYPES } from "../actions";
import images from "../../shared/images";

// type 1 = хабархо дар бораи фонд // 2 = дигар хабархо

export interface INews {
    id: number
    label: string
    text: string
    date: string
    type: number
    img: any
}

export interface INewsState {
    news: INews[]
}
let id: number = 1;
const news: INews[] = [
    {
        id: id++,
        date: "news1Date",
        label: "news1Label",
        text: "news1Text",
        img: [images.news1],
        type: 1,
    },{
        id: id++,
        date: "news2Date",
        label: "news2Label",
        text: "news2Text",
        img: [
            images.news2_1,
            images.news2_2,
            images.news2_3,
            images.news2_4,
            images.news2_5,
            images.news2_6,
        ],
        type: 1,
    },{
        id: id++,
        date: "news3Date",
        label: "news3Label",
        text: "news3ext",
        img: [],
        type: 1,
    },{
        id: id++,
        date: "news4Date",
        label: "news4Label",
        text: "news4Text",
        img: [],
        type: 1,
    },
];

const defaultState: INewsState = {
    news
};

const newsReducer: Reducer<INewsState, any> = (state = defaultState, action): INewsState => {
    switch (action.type) {
        default:
            return state
    }
};

export default newsReducer;