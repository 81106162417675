import {combineReducers} from 'redux'
import language from './language'
import menuList from './menuList'
import news from './news'
import projects from './projects'

export const rootReducer = combineReducers({
    language,
    menuList,
    news,
    projects,
});

