import {Reducer} from "redux";
import { ACTION_TYPES } from "../actions";
import { LOCALES } from "../../translates";

export interface ILanguageState {
    language: LOCALES
}

const defaultState = {
    language: LOCALES.TJ
};

const languageReducer: Reducer<ILanguageState, any>  = (state = defaultState, action): ILanguageState => {
    switch (action.type) {
        case ACTION_TYPES.CHANGE_LANGUAGE:
            const {language} = action;
            return {...state, language}
        default:
            return state
    }
};

export default languageReducer;