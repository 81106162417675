const images = {
    news1: require("./news1.jpg"),
    news2_1: require("./news2_1.jpg"),
    news2_2: require("./news2_2.jpg"),
    news2_3: require("./news2_3.jpg"),
    news2_4: require("./news2_4.jpg"),
    news2_5: require("./news2_5.jpg"),
    news2_6: require("./news2_6.jpg"),
    // news2: require("../../Components/MainSectionContent/img_1.jpg"),

    mainLogo: require("../../Components/Header/Logo.png"),
    mainLogoTj: require("../../Components/Header/LogoTj.png"),
    partner1: require("./partner1.jpg"),
    partner2: require("./partner2.jpg"),

    projectLogo1: require("./projectLogo1.png"),
    projectLogo2: require("./projectLogo2.jpg"),
    projectLogo3: require("./projectLogo3.jpg"),
    projectLogo4: require("./projectLogo4.jpg"),
    projectLogo5: require("./projectLogo5.jpg"),
    projectLogo6: require("./projectLogo6.jpg"),
    projectLogo7: require("./projectLogo7.jpg"),
    projectLogo8: require("./projectLogo8.png"),

    sokhtor: require("./sokhtor.png"),

};

export default images;