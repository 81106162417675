import { createStore, compose, applyMiddleware, Store } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from './reducers';
import { ILanguageState } from './reducers/language';
import { IMenuListState } from './reducers/menuList';
import { INewsState } from './reducers/news';
import { IProjectsState } from './reducers/projects';

export interface IState {
    language: ILanguageState
    menuList: IMenuListState
    news: INewsState
    projects: IProjectsState
}

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store: Store<IState> = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
)

export default store