import React, { Component, lazy, Suspense } from 'react'
import { DispatchProp, connect } from 'react-redux'
import { IState } from './store'
import { Router, Switch, Route } from 'react-router-dom';
import "./App.css"
import { LOCALES } from './translates';
import history from './history';

interface Props {
  language: LOCALES
}

const AboutComponentPage = lazy(() => import('./pages/AboutPage'));
const NewsComponentPage = lazy(() => import('./pages/NewsPage'));
const ContactsComponentPage = lazy(() => import('./pages/ContactsPage'));
const FormComponentPage = lazy(() => import('./pages/FormPage'));
const CurrentNewsComponentPage = lazy(() => import('./pages/CurrentNewsPage'));
const MainComponentPage = lazy(() => import('./pages/MainPage/MainPage'));
const DebtsComponentPage = lazy(() => import('./pages/DebtsPage'));
const ProjectsComponentPage = lazy(() => import('./pages/ProjectsPage'));
const CurrentProjectPage = lazy(() => import('./pages/CurrentProjectPage'));

class App extends Component<Props & DispatchProp> {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router history={history}>
          <Switch>
            <Route exact path="/about" component={AboutComponentPage} />
            <Route exact path="/news" component={NewsComponentPage} />
            <Route exact path="/projects" component={ProjectsComponentPage} />
            <Route exact path="/contacts" component={ContactsComponentPage} />
            <Route exact path="/apply" component={FormComponentPage} />
            <Route exact path="/debts" component={DebtsComponentPage} />
            <Route exact path="/news/:id" component={CurrentNewsComponentPage} />
            <Route exact path="/projects/:id" component={CurrentProjectPage} />

            <Route component={MainComponentPage} />
            {/* <Route component={NotFoundPage}/> */}
          </Switch>
        </Router>
      </Suspense>
    )
  };
};

const mapStateToProps = (state: IState) => ({
  language: state.language.language
});

export default connect(mapStateToProps)(App);