import { Reducer } from "redux";
import { ACTION_TYPES } from "../actions";

export enum EMenuItems {
    MAIN = "main",
    ABOUT = "about",
    NEWS = "news",
    DEBTS  ="debts",
    PROJECTS = "projects",
    CONTACTS = "contacts"
}

export interface IMenuList {
    id: number
    name: string
}

let menuId: number = 1;

const menuList: IMenuList[] = [
    { id: menuId++, name: EMenuItems.MAIN },
    { id: menuId++, name: EMenuItems.ABOUT },
    { id: menuId++, name: EMenuItems.NEWS },
    { id: menuId++, name: EMenuItems.DEBTS },
    { id: menuId++, name: EMenuItems.PROJECTS },
    { id: menuId++, name: EMenuItems.CONTACTS }
];

export interface IMenuListState {
    currentMenuId: number
    menu: IMenuList[]
    smallMenuIsOpen: boolean
}

const defaultState: IMenuListState = {
    currentMenuId: menuList[0].id,
    menu: menuList,
    smallMenuIsOpen: false,
};

const menuListReducer: Reducer<IMenuListState, any> = (state = defaultState, action): IMenuListState => {
    switch (action.type) {
        case ACTION_TYPES.CHANGE_CURRENT_MENU_ID:
            const { currentMenuId } = action;
            return { ...state, currentMenuId }
        case ACTION_TYPES.TOGGLE_SMALL_MENU_IS_OPEN:
            return {...state, smallMenuIsOpen: action.isOpen}
        default:
            return state
    }
};

export default menuListReducer;